<template>
  <div
    class="
      widgetContainer
      bg-white
      widgetContainer--scrollable
      paySuccess paySuccess--completed
    ">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          Paid Successfully
        </p>
        <span class="el-icon-close" @click="done" />
      </div>
    </div>
    
    <div class="widgetContainer__body">
      <div class="payment-done-header">
        <LottieAnimation animation-name="success" reference="pay-success" :loop="true" />
        
        <p class="title">
          {{
            $t('paid_amount_title', {
              value1: amount
            })
          }}
        </p>
        <p class="description" v-if="getPaymentType === 'ach'">
          {{
            $t('pay_success_description', {
              value1: getSelectedContactData.name
            })
          }}
        </p>
        <p class="description" v-else-if="getPaymentType === 'check'">
          {{
            $t('pay_check_success_description', {
              value1: getSelectedContactData.name
            })
          }}
        </p>
        <p class="description" v-else-if="getPaymentType === 'cardPayment'">
          {{
            $t('pay_card_success_description', {
              value1: getSelectedContactData.name
            })
          }}
        </p>
        
        <p class="description" v-else>
          {{ $t('pay_intra_success_description') }}
        </p>
      </div>

      <div class="paySuccess__details">
        <div class="listWithInfo border-on">
          <ul>
            <li>
              <span class="label">{{ $t('pay_section_source') }}</span>
              <span class="value">
                {{ getSelectedAccount.label }}
              </span>
            </li>
            <li>
              <span class="label">{{ $t('pay_section_destination') }}</span>
              <span class="value">
                {{ getCompletedPaymentDetails.name }}
              </span>
            </li>
            <template
              v-if="
                getPaymentType === 'ach' || getPaymentType === 'domesticWire'
              ">
              <li>
                <span class="label">{{ $t('contact_Account_AccNo') }}</span>
                <span class="value">
                  {{ getCompletedPaymentDetails.accountNumber }}
                </span>
              </li>
              <li>
                <span class="label">{{ $t('contact_Account_RoutingNo') }}</span>
                <span class="value">
                  {{ getCompletedPaymentDetails.routingNumber }}
                </span>
              </li>
              <li>
                <span class="label">{{ $t('payment_sucess_bankname') }}</span>
                <span class="value" v-if="getPaymentType === 'ach'">
                  {{ getSelectedContactData.ach.bankName }}
                </span>
                <span
                  class="value"
                  v-else-if="getPaymentType === 'domesticWire'">
                  {{ getSelectedContactData.wire.domestic.bankName }}
                </span>
              </li>
              <li>
                <span class="label">{{ $t('contact_Account_Type') }}</span>
                <span class="value">
                  {{ accountTypeName }}
                </span>
              </li>
            </template>
            <template
              v-else-if="
                getPaymentType === 'check' && getCompletedPaymentDetails.address
              ">
              <li>
                <span class="label">{{ $t('address') }}</span>
                <span class="value">
                  {{
                    getCompletedPaymentDetails.address.line1 +
                      ',' +
                      (getCompletedPaymentDetails.address.line2 !== ''
                        ? getCompletedPaymentDetails.address.line2 + ','
                        : '') +
                      getCompletedPaymentDetails.address.city +
                      ',' +
                      getCompletedPaymentDetails.address.state +
                      ' ' +
                      getCompletedPaymentDetails.address.postalCode
                  }}
                </span>
              </li>
            </template>
            <template
              v-else-if="
                getPaymentType === 'cardPayment'
              ">
              <li v-if="getSelectedContactData.email && getSelectedContactData.email !== ''">
                <span class="label">{{ $t('email') }}</span>
                <span class="value">
                  {{ getSelectedContactData.email }}
                </span>
              </li>
              <li>
                <span class="label">{{ $t('contact_phone_title') }}</span>
                <span class="value">
                  {{ getSelectedContactData.phone }}
                </span>
              </li>
            </template>
            <li>
              <span class="label">{{ $t('pay_row_purpose') }}</span>
              <span class="value">
                {{ getCompletedPaymentDetails.description }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button type="primary" class="width-100 el-button__brand brand" @click="done">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { accountType } from '@m/contact/enums';
import LottieAnimation from '@/components/LottieAnimation.vue';
import { currencyFormatter } from '@/utils/general';

export default {
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters('pay', ['getPaymentType', 'getCompletedPaymentDetails']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    accountTypeName() {
      return accountType[this.getCompletedPaymentDetails.accountType];
    },
    amount() {
      return currencyFormatter(this.getCompletedPaymentDetails.amount);
    }
  },
  created() {
    if (!this.getPaymentType) this.$router.push('/pay/paymentType');
  },
  methods: {
    done() {
      this.drawerClose();
      this.goToDashboard();
    }
  }
};
</script>
<style lang="scss">
.paySuccess {
  &--completed {
    padding-top: 65px;

    .payment-done-header {
      text-align: center;
      padding-bottom: 48px;

      .welcome__illustration {
        margin: 0 auto;
        width: 150px;
      }
    }

    .icon {
      font-size: 75px;
      margin-bottom: 36px;
      display: inline-block;

      .path1 {
        &:before {
          color: var(--color-green);
        }
      }
    }

    .title {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 16px;
    }

    .description {
      color: rgba(#3c3c43, 0.6);
      font-size: 14px;
      line-height: 20px;
    }

    .el-button {
      margin-top: auto;
      width: 100%;
    }
  }

  &__details {
    .header-1 {
      text-transform: capitalize;
    }

    .sub-header {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }

    .contact-info {
      background: #fff;
      border-radius: 10px;
      padding: 10px 16px;
      margin-bottom: 30px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .value {
          font-weight: 500;
          padding-left: 8px;
        }
      }
    }
  }

  .purpose-input {
    outline: none;
    border: none;
    text-align: right;
    input {
      text-align: right;
      font-weight: bold;
      color: #000000;
    }
  }
  .success-desc {
    font-size: 17px;
    color: #3c3c43;
    margin-bottom: 25px;
  }

  &__done-btn {
    width: 100%;
  }
}
</style>
